import { Elm } from "../app/src/Main.elm";
import "./elm-title-element.js";
import "./pdf-viewer-element.js";
import ControllingManagerPopover from "./controlling-manager-popover.js";
import "./ingo-1.instant.payments.sdk-2.2.0.min.js";
import { fetch as fetchPolyfill } from "whatwg-fetch";
import Honeybadger from "@honeybadger-io/js";
import { setupCSRFXHR } from "./csrf-xhr.js";

window.Honeybadger = Honeybadger;

const OktaSignIn = window["OktaSignIn"];

function bootstrap() {
  const globalConfig = JSON.parse(window.globalConfig);

  const csrfToken = globalConfig.csrfToken;
  setupCSRFXHR(csrfToken);

  ControllingManagerPopover.setup(globalConfig.frontendCss);

  let lastSessionRefresh = new Date().getTime();

  const elmAppElement = document.getElementById("elm-app");
  if (elmAppElement == null) {
    throw `unable to locate element element to bind to`;
  }

  const elmApp = Elm.Main.init({
    node: elmAppElement,
    flags: {
      initialLocation: window.location.href,
      mainFlags: {
        globalConfig: globalConfig,
        initialViewportWidth: window.innerWidth,
      },
    },
  });

  // make it easier to test elm ports by sending commands
  // via browser's console
  window.elmApp = elmApp;
  let isNative = false;

  // functions explicitly available for the cordova mobile app
  // make sure to preserve backwards compatibility
  window.mobile = {
    setDevice: function (deviceConfig) {
      window.elmApp.ports.setDevice.send(deviceConfig);
    },
    setDataToRequestPushProvisioning: function (data) {
      window.elmApp.ports.setDataToRequestPushProvisioning.send(data);
    },
    finishedBiometrics: function (data) {
      window.elmApp.ports.finishedBiometrics.send(data);
    },
    checkBiometricsIfLoggedIn: function () {
      callRefreshToken(function () {
        // still logged in, so check biometrics again
        sendToCordova("showBiometrics");
      });
    },
    receivedProvisionStatus: function (data) {
      window.elmApp.ports.receivedProvisionStatus.send(data);
    },
  };
  elmApp.ports["nativeDeviceSet"].subscribe(function () {
    isNative = true;
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    const origContent = viewportMeta.getAttribute("content");
    viewportMeta.setAttribute(
      "content",
      origContent + ",maximum-scale=1,user-scalable=no",
    );
  });

  document.addEventListener("click", function (event) {
    if (isNative) {
      const externalLinkParent = event.target.closest("[data-external-link]");
      if (externalLinkParent) {
        event.preventDefault();
        const url = externalLinkParent.dataset.externalLink;
        sendToCordova("openInBrowser", { url: url });
      }
    }
  });

  elmApp.ports["logoutOkta"].subscribe(function ({
    inactivity: inactivity,
    biometrics: biometrics,
    redirect: redirect,
  }) {
    // Build out params for the login page after logging out
    const params = [];
    if (inactivity) {
      params.push("inactivity=1");
    }
    if (biometrics) {
      params.push("biometrics=1");
    }
    if (redirect != null) {
      params.push("redirect=" + redirect);
    }

    const paramsString = params.length > 0 ? "?" + params.join("&") : "";
    const localOkta = getOktaSignIn(globalConfig, "login", false);

    localOkta.authClient.signOut({
      postLogoutRedirectUri:
        globalConfig.userAuthOktaPostLogoutRedirectUri + paramsString,
    });
  });

  // Inform app of browser navigation (the BACK and FORWARD buttons)
  window.addEventListener("popstate", function () {
    elmApp.ports.onUrlChange.send(location.href);
  });

  window.addEventListener("click", function (event) {
    refreshSessionOrLogout();
  });

  window.addEventListener("keydown", function (event) {
    if (["Enter", "Tab"].includes(event.key)) {
      refreshSessionOrLogout();
    }
  });

  window.onbeforeunload = function () {
    const isLocalhost = window.location.hostname == "localhost";
    const onCompanyApplicationPage = window.location.pathname.startsWith(
      "/app/company_application/",
    );
    if (!isLocalhost && onCompanyApplicationPage) {
      // Modern browsers will overwrite with their own messages.
      return "Are you sure you want to leave your LoadPay application?";
    }
  };

  window.addEventListener("message", function (event) {
    const galileoOrigin = new URL(
      `https://agserv-${globalConfig["galileoClientName"]}.${globalConfig["galileoEnvName"]}.gpsrv.com`,
    ).origin;

    if (event.origin == galileoOrigin) {
      // galileo message
      elmApp.ports.galileoIframeData.send(event.data);
    } else {
      // a to b message - could be from differnt domains sepending on the iframe
      const eventName = event.data.event || event.event;
      if (["iframe-click", "iframe-path-change"].includes(eventName)) {
        refreshSessionOrLogout();
        if (
          event.data["event"] == "iframe-path-change" &&
          typeof event.data["path"] !== "undefined"
        ) {
          window.history.pushState(
            {},
            "",
            `?path=${encodeURIComponent(event.data["path"])}`,
          );
        }
      }
    }
  });

  elmApp.ports.pushUrl.subscribe((newUrl) => {
    const changed = window.location.pathname != newUrl;
    window.history.pushState({}, "", newUrl);

    if (changed) {
      elmApp.ports.onUrlChange.send(location.href);
    }
  });

  elmApp.ports.replaceUrl.subscribe((newUrl) => {
    window.history.replaceState({}, "", newUrl);
    elmApp.ports.onUrlChange.send(location.href);
  });

  elmApp.ports.reportError.subscribe(function (error) {
    const lowerError = error.toLowerCase();
    const filtersInError = globalConfig.errorFilters.filter((filter) =>
      lowerError.includes(filter),
    );
    if (filtersInError.length > 0) {
      Honeybadger.notify(
        `Error filtered because it contained ${filtersInError.join(", ")}`,
      );
    } else {
      Honeybadger.notify(error);
    }
  });

  elmApp.ports["identifyCurrentUserToFullStory"].subscribe(function (identity) {
    /* eslint-disable camelcase */
    if (identity.userId) {
      Honeybadger.setContext({
        user_id: identity.userId,
      });
    } else if (identity.adminUserId) {
      Honeybadger.setContext({
        admin_user_id: identity.adminUserId,
      });
    }

    /* eslint-disable no-undef */
    if (typeof FS !== "undefined") {
      FS.identify(identity.id, identity);
    }

    if (typeof pendo !== "undefined") {
      pendo.initialize({
        visitor: {
          id: identity.id,
          email: identity.email,
          admin_user_id: identity.adminUserId,
          admin_email: identity.adminEmail,
        },
        account: {
          id: identity.companyId,
        },
      });
    }
    /* eslint-enable no-undef */
    /* eslint-enable camelcase */
  });

  elmApp.ports["showSignIn"].subscribe(function (pair) {
    const [elementId, flow, inviteTokenEmail] = pair;
    if (flow == "login") {
      // okta helpfully stores the flow in a few places. If you don't specify one,
      // the stored value is used. Clear those places so we get login with unlock and reset
      localStorage.removeItem("okta-cache-storage");
      localStorage.removeItem("okta-shared-transaction-storage");
      sessionStorage.removeItem("okta-transaction-storage");
    }

    const joiningExistingTeam =
      inviteTokenEmail && inviteTokenEmail.trim() !== "";
    const urlParams = new URLSearchParams(window.location.search);
    const inviteToken = urlParams.get("invite");
    const localOkta = getOktaSignIn(globalConfig, flow, joiningExistingTeam);
    // just in case we somehow get a message before elm finishes
    // rendering the element - wait for the rendering to complete.
    requestAnimationFrame(function () {
      const match = document.getElementById(elementId);
      if (match == null) {
        throw `unable to locate element to setup okta sign in ${elementId}`;
      }

      // Redirect to admin okta login widget if using an internal email address
      localOkta.on("afterRender", (_context) => {
        const nextButton = document.getElementsByClassName("button-primary")[0];
        const signupLink = document.getElementsByClassName("js-enroll")[0];
        const loginLink = document.getElementsByClassName("js-back")[0];
        const loginFromSignup = document.getElementsByClassName("js-cancel")[0];
        const finishedMfaButton =
          document.getElementsByClassName("skip-all")[0];
        const passcodeField = document.getElementsByName(
          "credentials.passcode",
        )[0];

        function goToLogin(event) {
          event.preventDefault();
          window.location = inviteToken
            ? `/app/login?invite=${encodeURIComponent(inviteToken)}`
            : "/app/login";
        }
        // On the signup page, add a description with a popover describing
        // what a controlling manager is.
        if (document.getElementById("user-auth-okta-signup")) {
          if (joiningExistingTeam) {
            joinTeamSetDescriptionAndEmail(inviteTokenEmail);
          } else {
            ControllingManagerPopover.render();
          }
        }

        if (finishedMfaButton) {
          if (finishedMfaButton.checkVisibility()) {
            finishedMfaButton.click();
            const content = document.getElementById("user-auth-okta-signup");
            content.innerHTML =
              "<div style='width: 100%; height: 200px'><div class='v3__skeleton' style='width: 88%; height: 200px;'>&nbsp;</div></div>";
          }
        }

        if (passcodeField) {
          passcodeField.setAttribute("autocomplete", "one-time-code");
        }

        if (signupLink) {
          signupLink.addEventListener("click", goToSignup, false);

          function goToSignup(event) {
            event.preventDefault();
            window.location = inviteToken
              ? `/app/signup?invite=${encodeURIComponent(inviteToken)}`
              : "/app/signup";
          }
        }

        if (loginFromSignup) {
          loginFromSignup.addEventListener("click", goToLogin, false);
        }

        if (loginLink) {
          loginLink.addEventListener("click", goToLogin, false);
        }

        if (nextButton) {
          nextButton.addEventListener("click", maybeRedirect, false);

          function maybeRedirect(event) {
            const email = document.getElementsByName("identifier")[0]?.value;
            if (email && isInternalEmail(email)) {
              event.preventDefault();
              window.location =
                "/app/admin-login?email=" + encodeURIComponent(email);
            }
          }

          function isInternalEmail(email) {
            email = email.trim().toLowerCase();
            return (
              email.endsWith("@triumphpay.com") ||
              email.endsWith("@tfin.com") ||
              email.endsWith("@tbcap.com") ||
              email.endsWith("@tbkbank.com")
            );
          }
        }
      });
      localOkta
        .showSignIn({
          el: `#${elementId}`,
        })
        .then(function (res) {
          const accessToken = res.tokens.accessToken.accessToken;
          window.oktaStuff = res;
          elmApp.ports["newOktaTokenReceived"].send(accessToken);
        })
        .catch(function (error) {
          console.log(`okta setup error`, error);
        });
    });
  });

  elmApp.ports["showAdminSignIn"].subscribe(function (elementId) {
    // just in case we somehow get a message before elm finishes
    // rendering the element - wait for the rendering to complete.
    requestAnimationFrame(function () {
      const queryParams = new URLSearchParams(window.location.search);
      const email = queryParams.get("email");
      const match = document.getElementById(elementId);
      if (match == null) {
        throw `unable to locate element to setup okta sign in ${elementId}`;
      }
      const oktaAdminSignIn = getOktaAdminSignIn(globalConfig, email);
      oktaAdminSignIn
        .showSignIn({
          el: `#${elementId}`,
        })
        .then(function (res) {
          const accessToken = res.tokens.accessToken.accessToken;
          elmApp.ports["newOktaAdminTokenReceived"].send(accessToken);
        })
        .catch(function (error) {
          console.log(`okta setup error`, error);
        });
    });
  });

  elmApp.ports.showIngoIframe.subscribe(function (params) {
    const authorizedUrl = params[0];
    const containerClass = params[1];
    const iframeClass = "ingo-web-plugin";
    const modal = document.getElementsByClassName(containerClass)[0];

    if (modal && authorizedUrl != "") {
      const webPlugin = IngoInstantPayments.create(modal, {
        cssName: iframeClass,
        autoHeight: false,
        scrolling: true,
      });
      webPlugin.mount(
        authorizedUrl,
        IngoInstantPayments.FUNDING_DESTINATIONS.DEBIT,
      );

      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.PAGE_LOAD,
        function (data) {
          console.log("page-load", data);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.PLUGIN_RESIZE,
        function (data) {
          console.log("plugin-resize", data);
          document.getElementsByClassName(iframeClass)[0].style["height"] =
            `${data.content.height}px`;
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.TOKEN_SUCCESS,
        function (data) {
          console.log("token-success", data);
          elmApp.ports["ingoCustomerAccountTokenReceived"].send(
            data.customer_account_token,
          );
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.FUNDING_CANCELED,
        function (data) {
          console.log("funding-canceled", data);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.TERMINAL_FAILURE,
        function (data) {
          console.log("terminal-failure", data);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.PLUGIN_ERROR,
        function (data) {
          console.log("plugin-error", data);
          elmApp.ports["ingoCustomerAccountTokenFailed"].send(null);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.SESSION_ERROR,
        function (data) {
          console.log("session-error", data);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.FUNDING_CANCELED,
        function (data) {
          console.log("funding-canceled", data);
          elmApp.ports["ingoFundingCanceled"].send(null);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.TERMINAL_FAILURE_ACKNOWLEDGED,
        function (data) {
          console.log("terminal-failure-acknowledged", data);
          elmApp.ports["ingoTerminalFailureAcknowledged"].send(null);
        },
      );
      webPlugin.addEventListener(
        IngoInstantPayments.EVENTS.MAX_VERIFICATION_ATTEMPTS_EXCEEDED,
        function (data) {
          console.log("max-verification-attempts-exceeded", data);
          elmApp.ports["ingoCustomerAccountTokenFailed"].send(null);
        },
      );
    }
  });

  function sendToCordova(tag, params) {
    console.log("Sending to cordova:", tag, JSON.stringify(params));
    const cordovaTarget =
      window["cordova_iab"] ?? window["webkit"]?.messageHandlers?.cordova_iab;
    if (cordovaTarget) {
      cordovaTarget.postMessage(
        JSON.stringify({
          tag: tag,
          params: params,
        }),
      );
    } else {
      console.error("Could not find cordova!");
    }
  }
  elmApp.ports["nativeAppleGenerateDataToRequestPushProvision"].subscribe(
    function (params) {
      sendToCordova("generateDataToRequestPushProvision", params);
    },
  );
  elmApp.ports["nativeAndroidGenerateDataToRequestPushProvision"].subscribe(
    function (params) {
      sendToCordova("generateDataToRequestPushProvision", params);
    },
  );
  elmApp.ports["nativeAppleRequestPushProvision"].subscribe(function (params) {
    sendToCordova("requestPushProvision", params);
  });
  elmApp.ports["nativeAndroidRequestPushProvision"].subscribe(
    function (params) {
      sendToCordova("requestPushProvision", params);
    },
  );
  elmApp.ports["nativeShare"].subscribe(function (params) {
    sendToCordova("share", params);
  });
  elmApp.ports["nativeDownload"].subscribe(function (params) {
    sendToCordova("download", params);
  });
  elmApp.ports["nativeCheckIfPushProvisioned"].subscribe(function (params) {
    sendToCordova("checkIfPushProvisioned", params);
  });
  elmApp.ports["nativeOpenWallet"].subscribe(function (params) {
    sendToCordova("openWallet", params);
  });

  function refreshSessionOrLogout() {
    const time = new Date().getTime();

    if (
      window.location.pathname.startsWith("/app/login") ||
      window.location.pathname.startsWith("/app/admin-login") ||
      window.location.pathname.startsWith("/app/signup")
    ) {
      return;
    }

    if (lastSessionRefresh === null) {
      lastSessionRefresh = time;
    }

    // If it's been more than a minute, refresh session
    if (time > lastSessionRefresh + 60 * 1000) {
      lastSessionRefresh = time;
      callRefreshToken();
    }
  }

  function callRefreshToken(onSuccess) {
    let retries = 3;
    run();

    function run() {
      const isIE = !!window.MSInputMethodContext && !!document.documentMode;
      const fetch = isIE ? fetchPolyfill : window.fetch;
      fetch("/user_service/refresh_session", {
        method: "post",
        headers: new Headers({
          "content-type": "application/json",
          "X-CSRF-Token": globalConfig.csrfToken,
        }),
        body: "{}",
      })
        .then((response) => response.json())
        .then((response) => {
          // Logout if session expired
          if (response.session.userId == null) {
            elmApp.ports.onUrlChange.send(
              location.origin +
                "/app/logout?inactivity=1&redirect=" +
                encodeURI(location.href),
            );
          } else {
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((error) => {
          if (retries--) {
            run();
          }
        });
    }
  }
}

function getOktaSignIn(config, flow, joinExistingTeam) {
  // Don't pass the flow parameter if it's the default login flow so that the user can reset their
  // password if needed.
  // https://support.okta.com/help/s/article/Error-in-the-Password-Widget-flow-for-the-selfhosted-Okta-Signin-widget-enabled-with-Interaction-Code-flow?language=en_US
  const flowParameter = flow === "login" ? {} : { flow: flow };
  return new OktaSignIn({
    issuer: config.userAuthOktaIssuer, // 'https://{yourOktaDomain}/oauth2/default',
    clientId: config.userAuthOktaClientIdentifier, //'{{clientId of your OIDC app}}',
    redirectUri: config.userAuthOktaCallbackUri, //'{{redirectUri configured in OIDC app}}',
    state: "", // state can be any string, it will be passed on redirect callback
    authParams: {
      postLogoutRedirectUri: config.userAuthOktaPostLogoutRedirectUri, //'{{postLogoutRedirecturi configured in OIDC app}}',
    },
    i18n: {
      // Overriding English properties
      // See https://github.com/okta/okta-signin-widget/blob/master/packages/%40okta/i18n/src/properties/login.properties
      // for the list of overridable strings
      en: {
        "oie.registration.form.title": joinExistingTeam
          ? "Join your team on LoadPay"
          : "Let’s get started",
        "primaryauth.title": "Sign in to your account",
        "primaryauth.username.placeholder": "Username",
        "oie.phone.enroll.sms.subtitle":
          "When logging in to LoadPay, we'll send you an SMS message with a secure code. Message & data rates may apply. Contact support to change your authentication methods at any time.",
        "oie.phone.enroll.call.subtitle":
          "When logging in to LoadPay, we'll call you with a secure code. Contact support to change your authentication methods at any time.",
      },
    },
    ...flowParameter,
  });
}

function getOktaAdminSignIn(config, email) {
  return new OktaSignIn({
    issuer: config.adminUserAuthOktaIssuer,
    clientId: config.adminUserAuthOktaClientIdentifier,
    redirectUri: config.adminUserAuthOktaCallbackUri,
    state: "", // state can be any string, it will be passed on redirect callback
    flow: "login",
    useClassicEngine: true,
    username: email || "",
    i18n: {
      // Overriding English properties
      // See https://github.com/okta/okta-signin-widget/blob/master/packages/%40okta/i18n/src/properties/login.properties
      // for the list of overridable strings
      en: {
        "primaryauth.title": "Sign in to your admin account",
        "primaryauth.username.placeholder": "Email address",
      },
    },
  });
}

function joinTeamSetDescriptionAndEmail(emailAddress) {
  const hasPasswordInstructions = document.getElementsByClassName(
    "password-authenticator--heading",
  )[0];
  const title = document.getElementsByClassName("okta-form-title")[0];

  if (hasPasswordInstructions) {
    const teamDescription = document.createElement("p");
    teamDescription.innerHTML =
      "Create sign in credentials to join your team on LoadPay";
    teamDescription.style.marginBottom = "32px";
    title.after(teamDescription);
    title.style.marginBottom = "8px";

    const emailFieldset = document.querySelector(
      '[data-se="o-form-fieldset-userProfile.email"]',
    );
    if (emailFieldset) {
      const emailInput = emailFieldset.querySelector(
        'input[name="userProfile.email"]',
      );
      if (emailInput) {
        emailInput.value = emailAddress;

        // Create and dispatch an input event to ensure form validation detects the change
        const event = new Event("input", { bubbles: true });
        emailInput.dispatchEvent(event);
      }
      emailFieldset.style.display = "none";
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {
  bootstrap();
});
